

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function Mesofaq() {
    return (
      <>
      <Separator />
        <Section py={4} id="faq" bg="#f7f7fb" className="">
      <Container className="pb-lg-5 mb pt-lg-5 pt-3 pb-3">
        <Row className="justify-content-center pb-5">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Mesotherapy</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       



        <Col
     
     >
          
         
    
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What is Mesotherapy?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Mesotherapy is a non-surgical cosmetic treatment that involves injecting a cocktail of vitamins, minerals, and amino acids into the skin to rejuvenate, hydrate, and enhance its appearance. This treatment targets the middle layer of the skin to promote collagen and elastin production, improving skin texture and tone.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Is Mesotherapy Safe?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Yes, mesotherapy is safe when performed by trained professionals. At Dermamina, only natural products are used in the injections, minimizing the risk of allergies or infections. The treatment is widely favored for its safety profile and minimal side effects.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What Skin Concerns Can Mesotherapy Address?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Mesotherapy can treat a variety of skin concerns, including dull and tired-looking skin, fine lines and wrinkles, hyperpigmentation, and acne scarring. It helps improve blood circulation, flush out toxins, and boost the skin's natural hydration and firmness.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How Many Mesotherapy Sessions Are Needed?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    The number of sessions required varies depending on the individual's skin condition and goals. Typically, a course of 3 treatments spaced 4 weeks apart is recommended for optimal results. Maintenance sessions may be needed every 6-12 months.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Does Mesotherapy Hurt?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Mesotherapy has minimal pain as it involves using very fine needles. Some patients may experience slight discomfort, but the procedure is generally well-tolerated. A numbing cream can be applied to minimize any discomfort.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What Should I Expect After a Mesotherapy Session?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    After a mesotherapy session, you may experience slight redness, swelling, or bruising at the injection sites. These effects are temporary and usually subside within a few hours to a couple of days. It is advised to avoid washing the treated area for 5-6 hours post-treatment.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Are There Any Side Effects?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Mesotherapy has minimal side effects. Some patients might experience minor swelling, bruising, or discomfort around the treatment area, which typically resolves quickly. Detailed aftercare instructions will be provided to ensure a smooth recovery.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    How Long Do Mesotherapy Results Last?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Results from mesotherapy can last up to 3 months. For prolonged benefits, a series of treatments is recommended, with initial sessions spaced 4 weeks apart and maintenance sessions every 6-12 months.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    Why Choose Dermamina for Mesotherapy?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    Dermamina is renowned for delivering natural-looking results and providing honest assessments. If the treatment is deemed unsuitable for a patient, it will not be recommended. The clinic emphasizes patient safety and satisfaction through tailored treatments.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>
    <br />
    <Accordion allowZeroExpanded classname="">
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                    What Happens During a Mesotherapy Session?
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <p>
                    A mesotherapy session at Dermamina begins with a thorough consultation to understand your skin concerns and goals. The treatment involves injecting a customized blend of vitamins and minerals into the skin using fine needles. The procedure is quick, typically lasting around 20 minutes, with no downtime required.
                </p>
            </AccordionItemPanel>
        </AccordionItem>
    </Accordion>



      
<Accordion allowZeroExpanded >

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Why choose Dermamina for your Mesotherapy treatment?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We are renowned for providing natural looking results, and being completely honest with our patients. If we feel a treatment is not suitable for you, we will not recommend for you to go ahead with it.
        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded >
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What happens on the day?        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        We start the appointment with a thorough consultation to understand your requirements. Once we have a full understanding and you’re happy to proceed, we start the treatment. 
      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded >
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What if I change my mind after the consultation?     </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        In the case that you had booked in for a treatment and then during the consultation decided not to go ahead, you will only be charged for the consultation and the remaining of the deposit paid will be refunded or can be used towards another treatment.

        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    



        
</Col>



        </Row>

 


<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
              
                </Bookbutton> </a>
        


       


        </Container>
        </Section>
<SeparatorEnd />
        </>


    );
}








